import React, { useState, useRef, useEffect } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';
// import FOG from 'vanta/dist/vanta.fog.min';
import WAVES from 'vanta/dist/vanta.waves.min';
import * as THREE from 'three';

import AnimeArrive from './AnimeArrive';
import './AppConstruction.css';

function AppConstruction() {
  // const [cursorXY, setCursorXY] = useState({ x: -100, y: -100 })
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 28, stiffness: 600 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);
//vanta state
  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 25);
      cursorY.set(e.clientY - 25);
    }
    window.addEventListener('mousemove', moveCursor)

    return () => {
      window.removeEventListener('mousemove', moveCursor)
    }
   }, []);

//Vanta Use Effect
useEffect(() => {
  if (!vantaEffect) {
    // setVantaEffect(FOG({
    //   el: vantaRef.current,
    //   THREE: THREE,
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   minHeight: 200.00,
    //   minWidth: 200.00,
    //   highlightColor: 0xa281b3,
    //   midtoneColor: 0x717396,
    //   lowlightColor: 0x5d47c8,
    //   baseColor: 0x2c1735,
    //   blurFactor: 0.50,
    //   speed: 0.80,
    //   zoom: 1.60
    // }))
    setVantaEffect(WAVES({
      THREE: THREE,
      el: vantaRef.current,
      mouseControls: true,
      touchControls: true,
      gyroControls: true,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 4.00,
      scaleMobile: 1.00,
      color: 0x4b366b,
      shininess: 49.00,
      waveHeight: 34.50,
      waveSpeed: 0.45,
      zoom: 0.65
    }))
  };
  return () => {
    if (vantaEffect) vantaEffect.destroy()
  }
}, [vantaEffect]);

  const clickHandler = () => {
    window.location.href = "https://github.com/andrew-bray";
  }

  return (
    <div ref={vantaRef}>
      <div 
      className="appConstruction">

        <AnimeArrive text="This site is"
                    delay="100"
                    fontSize={window.innerWidth > 450 ? 2.5 : 1.7} />
        <div className="construction-flex">
          <AnimeArrive text=" under "
                      delay="1000"
                      fontSize={window.innerWidth > 470 ? 4 : 2.7}/>
          <AnimeArrive text="construction"
                      delay="1300"
                      fontSize={window.innerWidth > 470 ? 4 : 2.7}/>

        </div>
        <p onClick={clickHandler}
        className="fade-in">Click on this Portal to check out Andrew Bray's github collection</p>
        <motion.div className="cursor"
        style={{ 
          translateX: cursorXSpring,
          translateY: cursorYSpring
        }} />
      </div>
    </div>
  );
}

export default AppConstruction;