import React from 'react'
import PropTypes from 'prop-types'
import Anime from 'react-anime'

const AnimeArrive = ( {text, delay, fontSize} ) => {
  const bannerSpans = [...text].map((el, i) => (
    <span className="letter" key={i}>{el}</span>
  ));

  return (
    <div className="anime-container"
      style={{ fontSize: `${fontSize}rem`}}>
      <Anime
            loop={false}
            scale={[fontSize * 1.5, 1]}
            opacity={[0, 1]}
            rotate={[(el) => Math.random()* 12 - 6, (el) => Math.random()* -12 + 6 ]}
            translateX={[(el, i) => (15*i) - (fontSize*25), 0]}
            translateZ={0}
            easing="easeOutBounce"
            duration={1200}
            delay={delay}
            
            >
        {bannerSpans}
      </Anime>
    </div>
  )
}

AnimeArrive.propTypes = {
  text: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired
}

export default AnimeArrive
